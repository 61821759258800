.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom, #0C2438, #0E2A42, #16456C, #286BA575);
    height: 100vh;
}

.login-button{
    margin-top: 20px;
}

.login-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #fff;
}

label {
    margin-bottom: 30px;
}